<template>
  <CRow>
    
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <strong>Promocode</strong>
        </CCardHeader>
        <CCardBody>
          <CForm @submit.prevent="newPromocode" id="form">
            <CRow>
              <CCol col=12>
                <CAlert
                  :show.sync="dismissCountDown"
                  closeButton
                  color="danger"
                  fade
                >
                  {{errorMsg}}
                </CAlert>
              </CCol>
              
              <CCol col=6>
                <CInput
                  label="Código"
                  v-model="code"
                  bottom
                  type="text"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Desconto (%)"
                  v-model="discount"
                  bottom
                  type="number"
                  min="1" max="100"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Data de expiração"
                  v-model="expire_date"
                  bottom
                  type="date"
                  required
                />
              </CCol>

              <CCol col=5>
                <CInput
                  label="Limite de utilizações"
                  v-model="max_uses"
                  bottom
                  type="number"
                  min="1" max="10000"
                  required
                />
              </CCol>

              <CCol col=1 class="text-center">
                <CInput
                  label="Ativo"
                  v-model="active"
                  bottom
                  type="checkbox"
                  :checked="active"
                />
              </CCol>
              
              <CCol col=12 class="text-center">
                <CButton size="sm" color="warning" @click="goBack()" style="margin-right: 10px;">Voltar</CButton>
                <CButton type="submit" size="sm" color="success">Salvar</CButton>
              </CCol>
            </CRow>
            
          </CForm>
        </CCardBody>
        
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Promocodes from "@/services/PromocodeDataService";
export default {
  name: 'Promocode',
  data: () => {
    return {
      id: 0,
      dismissCountDown: 0,
      errorMsg: '',
      active: true,
      code: '',
      discount: '',
      expire_date: new Date(),
      max_uses: 0,
    }
  },
  methods: {
    
    goBack() {
      this.$router.push('/promocodes');
    },
    
    newPromocode() {
      Promocodes.newPromocode(this.active, this.code, this.discount, this.expire_date, this.max_uses)
      .then(response => {
        let message = {
          status: 'success',
          text: '<strong>Promocode adicionado:</strong> ' + response.data.id+' - '+response.data.code
        }
        EventBus.$emit('showMessage', message);
        this.$router.push('/promocodes');
      })
      .catch(e => {
        alert(e);
      });
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    },
    showAdminOrModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR') || this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
  },
  mounted () {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
}
</script>
